import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import { CommentOutlined, MenuOutlined, PlusOutlined, HomeOutlined, UserOutlined, ProfileOutlined, LogoutOutlined } from "@ant-design/icons";
import { Card, Row, Col, message, Spin, Drawer, Menu } from "antd";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import UserService from "../services/user.service";
import BotService from "../services/bot.service";
import AuthService from "../services/auth.service";

const { Meta } = Card;

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.onClickChatWithBot = this.onClickChatWithBot.bind(this);
    this.onCloseDrawer = this.onCloseDrawer.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.logOut = this.logOut.bind(this);

    this.state = {
      bots: [],
      chatWithBotNavFlag: false,
      selectedBot: {},
      redirect: null,
      isLoading: false,
      loadingTxt: "Loading...",
      drawerVisible: false,
      sLogin: false,
    };
  }

  componentWillMount() {
    this.setState({ sLogin: localStorage.getItem('user') == null ? true : false })
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    UserService.getPublicContent()
      .then((res) => {
        if (res.status == 200) {
          localStorage.setItem("admin", JSON.stringify(res.data.data));
          BotService.getActiveBots()
            .then((res1) => {
              if (res1.status == 200) {
                this.setState({
                  bots: res1.data.data.bots,
                });
              } else if (res1.status == 404) {
                message.info("There is no bots.");
              } else {
                message.error(
                  "Sorry, but there is a little problem. Try again after a while."
                );
              }
            })
            .catch((err) => {
              message.error(
                `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
                  JSON.parse(localStorage.getItem("admin")).email
                }.`
              );
            });
        } else if (res.status == 404) {
          message.info("There is no admin.");
        } else {
          message.error(
            "Sorry, but there is a little problem. Try again after a while."
          );
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        message.error(
          `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
            JSON.parse(localStorage.getItem("admin")).email
          }.`
        );
      });
  }

  onClickChatWithBot(bot) {
    this.setState({
      redirect:
        "/chat?id=" +
        bot.id +
        "&name=" +
        bot.bot_name +
        "&image=" +
        bot.img_url +
        "&description=" +
        bot.description,
    });
  }

  onCloseDrawer() {
    this.setState({ drawerVisible: false });
  }

  onMenuClick() {
    this.setState({ drawerVisible: false });
  }

  logOut() {
    AuthService.logout();
    window.location.reload();
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    const items1 = [
      { item: 'key1', label: <Link to="/login">LogIn</Link>, icon: <HomeOutlined /> },
    ]

    const items2 = [
      { item: 'key1', label: <Link to="/home">Home</Link>, icon: <HomeOutlined /> },
      { item: 'key2', label: <Link to="/admin">Admin</Link>, icon: <UserOutlined /> },
      { item: 'key3', label: <Link to="/profile">Profile Setting</Link>, icon: <ProfileOutlined /> },
      { item: 'key4', label: <a onClick={this.logOut}>LogOut</a>, icon: <LogoutOutlined /> }
    ]

    return (
      <>
        
        <Row>
          <Col xs={24} sm={20} className="admin_breadcrumb">
            <header style={{ padding: '20px 25px', textAlign: 'center', backgroundColor: '#5eb2f1', marginBlock: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div className="drawer_button" style={{ color: 'white', fontWeight: 'bold', fontSize: '24px', lineHeight: '0', cursor: 'pointer' }} onClick={() => { this.setState({ drawerVisible: true })}}>
                <MenuOutlined />
              </div>
              <h3 style={{ marginBlock: '0', color: 'white' }}>Home</h3>
              <div className="drawer_button" style={{ color: 'white', fontWeight: 'bold', fontSize: '24px', lineHeight: '0', cursor: 'pointer', visibility: 'hidden' }} onClick={this.onNewChatClick} >
                <PlusOutlined />
              </div>
            </header>
          </Col>
        </Row>
        <Row>
          <Col span={20} style={{ margin: 'auto' }}>
            <Swiper
              navigation={true}
              modules={[Navigation]}
              className="cardSwiperContainer"
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20
                },
                // when window width is >= 320px
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30
                },
                // when window width is >= 480px
                1280: {
                  slidesPerView: 4,
                  spaceBetween: 30
                },
                1440: {
                  slidesPerView: 5,
                  spaceBetween: 30
                }
              }}
            >
              {this.state.bots.map((bot) => (
                <SwiperSlide key={bot.id}>
                  <Card
                    className="card_item"
                    style={{
                      width: '100%',
                      padding: '20px 20px 0'
                    }}
                    cover={
                      <img
                        alt="img"
                        src={process.env.REACT_APP_BASE_API_URL + bot.img_url}
                        style={{ height: 'auto', maxWidth: 200, width: '100%', margin: 'auto' }}
                      />
                    }
                    actions={[
                      <CommentOutlined
                        key="chat"
                        style={{ color: "green" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.onClickChatWithBot(bot);
                        }}
                      />,
                    ]}
                  >
                    <Meta
                      title={<span title={bot.bot_name}>{bot.bot_name}</span>}
                      description={<span title={bot.description}>{bot.description}</span>}
                      style={{ maxHeight: "100" }}
                    />
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            zIndex: 999,
            top: 0,
            left: 0,
            display: `${this.state.isLoading ? "flex" : "none"}`,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin tip={this.state.loadingTxt}>
            <div style={{ width: "300px", height: "300px" }}></div>
          </Spin>
          <div className="menubar">
            <Drawer title="AI ChatBot" rootClassName="menu_drawer" width={'240px'} placement="right" onClose={this.onCloseDrawer} open={this.state.drawerVisible}>
              <Menu
                onClick={this.onMenuClick}
                style={{
                  width:'100%',
                }}
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                mode="inline"
                items={this.state.sLogin ? items1 : items2}
              />
            </Drawer>
          </div>
        </div>
      </>
    );
  }
}
