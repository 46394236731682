import axios from "axios";

const API_URL = `${process.env.REACT_APP_BASE_API_URL}`;

class UserService {
  getPublicContent() {
    return axios
      .get(API_URL)
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err;
      });
  }
}

export default new UserService();
