import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import AuthService from "../services/auth.service";
import { 
  LockOutlined, 
  UserOutlined, 
  MailOutlined, 
  MenuOutlined, 
  PlusOutlined,
  HomeOutlined,
  ProfileOutlined,
  LogoutOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, message, Spin, Drawer, Menu } from "antd";

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: {
        email: "",
        firstname: "",
        lastname: "",
      },
      message: "",
      isLoading: false,
      loadingTxt: "Loading...",
      drawerVisible: false,
    };

    this.onFinishUpdateProfile = this.onFinishUpdateProfile.bind(this);
    this.onFinishUpdatePassword = this.onFinishUpdatePassword.bind(this);
    this.onCloseDrawer = this.onCloseDrawer.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (!currentUser) this.setState({ redirect: "/home" });
    this.setState({ currentUser: currentUser, userReady: true });
  }

  onFinishUpdateProfile(values) {
    this.setState({ isLoading: true, loadingTxt: "Changing your profile..." });
    AuthService.updateProfile(values.email, values.firstname, values.lastname)
      .then((res) => {
        if (res.status == 200) {
          window.location.reload();
        } else if (res.status == 400) {
          message.error("Wrong Request!");
        } else if (res.status == 404) {
          message.warning("Not Found!");
        } else {
          message.error(
            "Sorry, but there is a little problem. Try again after a while."
          );
        }
        this.setState({ isLoading: false, loadingTxt: "Loading..." });
      })
      .catch((err) => {
        message.error(
          `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
            JSON.parse(localStorage.getItem("admin")).email
          }.`
        );
      });
  }

  onFinishUpdatePassword(val) {
    this.setState({ isLoading: true, loadingTxt: "Changing your password..." });
    AuthService.updatePassword(val.oldPwd, val.newPwd)
      .then((res) => {
        if (res.status == 200) {
          message.success("success update password");
        } else if (res.status == 400) {
          message.error("Wrong Request!");
        } else if (res.status == 404) {
          message.warning("Not Found!");
        } else if (res.status == 401) {
          message.warning("Wrong old password!");
        } else {
          message.error(
            "Sorry, but there is a little problem. Try again after a while."
          );
        }
        this.setState({ isLoading: false, loadingTxt: "Loading..." });
      })
      .catch((err) => {
        message.error(
          `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
            JSON.parse(localStorage.getItem("admin")).email
          }.`
        );
      });
  }

  onCloseDrawer() {
    this.setState({ drawerVisible: false });
  }

  onMenuClick() {
    this.setState({ drawerVisible: false });
  }

  logOut() {
    AuthService.logout();
    window.location.reload();
  }

  render() {

    const items = [
      { item: 'key1', label: <Link to="/home">Home</Link>, icon: <HomeOutlined /> },
      { item: 'key2', label: <Link to="/admin">Admin</Link>, icon: <UserOutlined /> },
      { item: 'key3', label: <Link to="/profile">Profile Setting</Link>, icon: <ProfileOutlined /> },
      { item: 'key4', label: <a onClick={this.logOut}>LogOut</a>, icon: <LogoutOutlined /> }
    ]

    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <div>
        {this.state.userReady ? (
          <div>

            <Row>
              <Col xs={24} sm={20} className="admin_breadcrumb">
                <header style={{ padding: '20px 25px', textAlign: 'center', backgroundColor: '#5eb2f1', marginBlock: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <div className="drawer_button" style={{ color: 'white', fontWeight: 'bold', fontSize: '24px', lineHeight: '0', cursor: 'pointer' }} onClick={() => { this.setState({ drawerVisible: true })}}>
                    <MenuOutlined />
                  </div>
                  <h3 style={{ marginBlock: '0', color: 'white' }}>{this.state.currentUser.firstname + " " + this.state.currentUser.lastname + " Profile"}</h3>
                  <div className="drawer_button" style={{ color: 'white', fontWeight: 'bold', fontSize: '24px', lineHeight: '0', cursor: 'pointer', visibility: 'hidden' }} onClick={this.onNewChatClick} >
                    <PlusOutlined />
                  </div>
                </header>
                
                <div className="admin_breadcrumb" style={{ marginTop: '20px' }}>
                  <Form name="update_profile" onFinish={this.onFinishUpdateProfile}>
                    <Row gutter={24}>
                      <Col xs={24} sm={16}>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Email!",
                            },
                          ]}
                          initialValue={this.state.currentUser.email}
                        >
                          <Input
                            prefix={
                              <>
                                <MailOutlined className="site-form-item-icon" />
                                <span style={{ color: "grey" }}>
                                  Email&nbsp;-&nbsp;
                                </span>
                              </>
                            }
                            name="email"
                            placeholder="Email"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xs={24} sm={8}>
                        <Form.Item
                          name="firstname"
                          rules={[
                            {
                              required: true,
                              message: "Please input your firstname!",
                            },
                          ]}
                          initialValue={this.state.currentUser.firstname}
                        >
                          <Input
                            prefix={
                              <>
                                <UserOutlined className="site-form-item-icon" />
                                <span style={{ color: "grey" }}>
                                  Firstname&nbsp;-&nbsp;
                                </span>
                              </>
                            }
                            name="firstname"
                            placeholder="Firstname"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={8}>
                        <Form.Item
                          name="lastname"
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your lastname!",
                            },
                          ]}
                          initialValue={this.state.currentUser.lastname}
                        >
                          <Input
                            prefix={
                              <>
                                <UserOutlined className="site-form-item-icon" />
                                <span style={{ color: "grey" }}>
                                  Lastname&nbsp;-&nbsp;
                                </span>
                              </>
                            }
                            name="lastname"
                            placeholder="Lastname"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={8}>
                        <Form.Item shouldUpdate>
                          {() => (
                            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                              Save Changes
                            </Button>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>

                  <Form name="update_password" onFinish={this.onFinishUpdatePassword}>
                    <Row gutter={24}>
                      <Col xs={24} sm={16}>
                        <Form.Item
                          name="oldPwd"
                          rules={[
                            {
                              required: true,
                              message: "Please input your old password!",
                            },
                          ]}
                        >
                          <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            name="oldPwd"
                            placeholder="Old Password"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xs={24} sm={8}>
                        <Form.Item
                          name="newPwd"
                          rules={[
                            {
                              required: true,
                              message: "Please input your new password!",
                            },
                            {
                              min: 6,
                              message: "The password must be 6 characters at least",
                            },
                          ]}
                        >
                          <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            name="newPwd"
                            placeholder="New Password"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={8}>
                        <Form.Item
                          name="newPwdConf"
                          dependencies={["newPwd"]}
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your new password!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue("newPwd") === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "The two passwords that you entered do not match!"
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            name="newPwdConf"
                            placeholder="Confirm New Password"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={8}>
                        <Form.Item shouldUpdate>
                          {() => (
                            <Button type="primary" htmlType="submit" danger style={{ width: '100%' }}>
                              Update Password
                            </Button>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>

            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
          </div>
        ) : null}
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            zIndex: 999,
            top: 0,
            left: 0,
            display: `${this.state.isLoading ? "flex" : "none"}`,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin tip={this.state.loadingTxt}>
            <div style={{ width: "300px", height: "300px" }}></div>
          </Spin>
          <div className="menubar">
            <Drawer title="AI ChatBot" rootClassName="menu_drawer" width={'240px'} placement="right" onClose={this.onCloseDrawer} open={this.state.drawerVisible}>
              <Menu
                onClick={this.onMenuClick}
                style={{
                  width:'100%',
                }}
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                mode="inline"
                items={items}
              />
            </Drawer>
          </div>
        </div>
      </div>
    );
  }
}
