import {
  Table,
  Row,
  Col,
  Popconfirm,
  Button,
  Radio,
  message,
  Modal,
  Spin,
  Input,
  Menu,
  Drawer
} from "antd";
import Draggable from "react-draggable";
import { Link } from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  HomeOutlined,
  UserOutlined,
  ProfileOutlined,
  LogoutOutlined,
  MenuOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useEffect, useState, useRef } from "react";
import BotService from "../services/bot.service";
import AuthService from "../services/auth.service";

const { confirm } = Modal;
const { TextArea } = Input;

const ManageQA = () => {
  const draggleRef = useRef(null);

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [chatId, setChatId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTxt, setLoadingTxt] = useState("Loading...");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [selectedQA, setSelectedQA] = useState({});
  const [updatedQA, setUpdatedQA] = useState({
    question: "",
    option_a: "",
    option_b: "",
    option_c: "",
    option_d: "",
    answer: 0,
  });
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    trainDataId: 0,
    qType: 0,
  });

  const [drawerVisible, setdrawerVisible] = useState(false);

  const options = [
    { label: "Low", value: 0 },
    { label: "Middle", value: 1 },
    { label: "High", value: 2 },
  ];

  const HOQColumns = [
    {
      title: "Question",
      dataIndex: "question",
      sorter: true,
      width: "35%",
    },
    {
      title: "Answer",
      dataIndex: "answer",
      width: "55%",
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => (
        <>
          <EditOutlined
            key="chat"
            style={{ color: "green", marginRight: "20px" }}
            onClick={(e) => {
              e.preventDefault();
              handleEdit(record.id);
            }}
          />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => {
              e.preventDefault();
              handleDelete(record.id);
            }}
          >
            <DeleteOutlined
              key="edit"
              style={{ color: "red" }}
              onClick={(e) => {
                e.preventDefault();
              }}
            />
          </Popconfirm>
        </>
      ),
    },
  ];

  const MCQColumns = [
    {
      title: "Question",
      dataIndex: "question",
      sorter: true,
      width: "25%",
    },
    {
      title: "Options",
      width: "45%",
      dataIndex: "options",
      render: (_, record) => (
        <>
          <p>A. {record.option_a}</p>
          <p>B. {record.option_b}</p>
          <p>C. {record.option_c}</p>
          <p>D. {record.option_d}</p>
        </>
      ),
    },
    {
      title: "Answer",
      dataIndex: "answer",
      width: "20%",
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => (
        <>
          <EditOutlined
            key="chat"
            style={{ color: "green", marginRight: "20px" }}
            onClick={(e) => {
              e.preventDefault();
              handleEdit(record.id);
            }}
          />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => {
              e.preventDefault();
              handleDelete(record.id);
            }}
          >
            <DeleteOutlined
              key="edit"
              style={{ color: "red" }}
              onClick={(e) => {
                e.preventDefault();
              }}
            />
          </Popconfirm>
        </>
      ),
    },
  ];

  const [columns, setColumns] = useState(MCQColumns);

  useEffect(() => {
    var urlParams = BotService.parseURLParams(window.location.href);
    let data = tableParams;
    data.trainDataId = parseInt(urlParams["id"][0]);
    setTableParams({ ...data });
    setChatId(urlParams["chatid"][0]);
  }, []);

  useEffect(() => {
    if (tableParams.trainDataId != 0) fetchData();
  }, [JSON.stringify(tableParams)]);

  const fetchData = () => {
    setLoading(true);
    BotService.getQA(tableParams)
      .then((res) => {
        if (res.status == 200) {
          setData(res.data.data.qas);
          let data = tableParams;
          data.pagination.total = res.data.data.total;
          setTableParams({ ...data });
        } else if (res.status == 400) {
          message.error("Wrong Request!");
        } else if (res.status == 404) {
          setData([]);
          let data = tableParams;
          data.pagination.total = 0;
          setTableParams({ ...data });
          message.warning("No Q&A found!");
        } else {
          message.error(
            "Sorry, but there is a little problem. Try again after a while."
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error(
          `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
            JSON.parse(localStorage.getItem("admin")).email
          }.`
        );
      });
  };

  const onChangeQType = ({ target: { value } }) => {
    let data = tableParams;
    data.pagination.current = 1;
    data.pagination.pageSize = 10;
    data.qType = value;
    setTableParams({ ...data });
    setColumns(value ? HOQColumns : MCQColumns);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      ...tableParams,
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleDelete = (key) => {
    setIsLoading(true);
    setLoadingTxt("Deleting...");
    BotService.delQAbyId(key, tableParams.qType)
      .then((res) => {
        if (res.status == 200) {
          fetchData();
          message.success("The Q&A has been deleted successfully!");
        } else if (res.status == 400) {
          message.error("Wrong Request!");
        } else {
          message.error(
            "Sorry, but there is a little problem. Try again after a while."
          );
        }
        setIsLoading(false);
        setLoadingTxt("Loading...");
      })
      .catch((err) => {
        message.error(
          `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
            JSON.parse(localStorage.getItem("admin")).email
          }.`
        );
      });
  };

  const handleEdit = (key) => {
    BotService.getQAById(key, tableParams.qType)
      .then((res) => {
        if (res.status == 200) {
          setSelectedQA(res.data.data);
          setEditModalOpen(true);
        } else if (res.status == 400) {
          message.error("Wrong Request!");
        } else if (res.status == 404) {
          message.error("Not Found Selected QA!");
        } else {
          message.error(
            "Sorry, but there is a little problem. Try again after a while."
          );
        }
      })
      .catch((err) => {
        message.error(
          `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
            JSON.parse(localStorage.getItem("admin")).email
          }.`
        );
      });
  };

  const onClickReset = () => {
    confirm({
      title: "Are you sure to make this task?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setIsLoading(true);
        setLoadingTxt("Resetting...");
        BotService.resetQA(tableParams.trainDataId, tableParams.qType)
          .then((res) => {
            if (res.status == 200) {
              fetchData();
              message.success("All Q&A has been deleted successfully!");
            } else if (res.status == 400) {
              message.error("Wrong Request!");
            } else {
              message.error(
                "Sorry, but there is a little problem. Try again after a while."
              );
            }

            setIsLoading(false);
            setLoadingTxt("Loading...");
          })
          .catch((err) => {
            message.error(
              `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
                JSON.parse(localStorage.getItem("admin")).email
              }.`
            );
          });
      },
      onCancel() {},
    });
  };

  const onClickAddQA = () => {
    setIsLoading(true);
    setLoadingTxt("Adding...");
    BotService.addQA(tableParams.trainDataId, tableParams.qType)
      .then((res) => {
        if (res.status == 200) {
          fetchData();
          message.success("The Q&A has been added successfully!");
        } else if (res.status == 400) {
          message.error("Wrong Request!");
        } else if (res.status == 404) {
          message.error("Not Found This Traindata!");
        } else if (res.status == 204) {
          message.info("Not Extract QA anymore!");
        } else if (res.status == 501) {
          message.error(
            "Sorry, but the connection with OpenAI or DB is failed. Try again after a while."
          );
        } else {
          message.error(
            "Sorry, but there is a little problem. Try again after a while."
          );
        }
        setIsLoading(false);
        setLoadingTxt("Loading...");
      })
      .catch((err) => {
        message.error(
          `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
            JSON.parse(localStorage.getItem("admin")).email
          }.`
        );
      });
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const handleEditModalOk = (e) => {
    setIsLoading(true);
    setLoadingTxt("Updating...");
    selectedQA.qType = tableParams.qType;
    BotService.editQA(selectedQA)
      .then((res) => {
        if (res.status == 200) {
          setEditModalOpen(false);
          fetchData();
          message.success("The selected Q&A has been updated successfully!");
        } else if (res.status == 400) {
          message.error("Wrong Request!");
        } else {
          message.error(
            "Sorry, but there is a little problem. Try again after a while."
          );
        }
        setIsLoading(false);
        setLoadingTxt("Loading...");
      })
      .catch((err) => {
        message.error(
          `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
            JSON.parse(localStorage.getItem("admin")).email
          }.`
        );
      });
  };

  const handleEditModalCancel = (e) => {
    setEditModalOpen(false);
  };

  const onChangeQuestion = (e) => {
    let data = selectedQA;
    data.question = e.target.value;
    setSelectedQA({ ...data });
  };

  const onChangeOptionA = (e) => {
    let data = selectedQA;
    data.option_a = e.target.value;
    setSelectedQA({ ...data });
  };

  const onChangeOptionB = (e) => {
    let data = selectedQA;
    data.option_b = e.target.value;
    setSelectedQA({ ...data });
  };

  const onChangeOptionC = (e) => {
    let data = selectedQA;
    data.option_c = e.target.value;
    setSelectedQA({ ...data });
  };

  const onChangeOptionD = (e) => {
    let data = selectedQA;
    data.option_d = e.target.value;
    setSelectedQA({ ...data });
  };

  const onChangeRadioAnswer = ({ target: { value } }) => {
    let data = selectedQA;
    data.answer = value;
    setSelectedQA({ ...data });
  };

  const onChangeTxtareaAnswer = (e) => {
    let data = selectedQA;
    data.answer = e.target.value;
    setSelectedQA({ ...data });
  };

  const onMenuClick = () => {
    setdrawerVisible(true);
  }

  const onCloseDrawer = () => {
    setdrawerVisible(false);
  }

  const logOut = () => {
    AuthService.logout();
    window.location.href = '/home';
  }

  const menuitems = [
    { item: 'key1', label: <Link to="/home">Home</Link>, icon: <HomeOutlined /> },
    { item: 'key2', label: <Link to="/admin">Admin</Link>, icon: <UserOutlined /> },
    { item: 'key3', label: <Link to="/profile">Profile Setting</Link>, icon: <ProfileOutlined /> },
    { item: 'key4', label: <a onClick={logOut}>LogOut</a>, icon: <LogoutOutlined /> }
  ]

  return (
    <>
      <Row>
        <Col xs={24} sm={22} className="admin_breadcrumb">
          <header style={{ padding: '20px 25px', textAlign: 'center', backgroundColor: '#5eb2f1', marginBlock: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <div className="drawer_button" style={{ color: 'white', fontWeight: 'bold', fontSize: '24px', lineHeight: '0', cursor: 'pointer' }} onClick={() => { setdrawerVisible(true) }}>
              <MenuOutlined />
            </div>
            <h3 style={{ marginBlock: '0', color: 'white' }}>Q & A</h3>
            <div className="drawer_button" style={{ color: 'white', fontWeight: 'bold', fontSize: '24px', lineHeight: '0', cursor: 'pointer', visibility: "hidden" }} >
              <PlusOutlined />
            </div>
          </header>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }} className="qa">
        <Col xs={23} sm={18} style={{ margin: 'auto' }}>
          <Button
            type="primary"
            className="question_buttons"
            onClick={onClickAddQA}
          >
            Add
          </Button>
          <Button
            type="primary"
            className="question_buttons"
            style={{
              marginLeft: "10px",
            }}
            onClick={onClickReset}
            danger
          >
            Reset
          </Button>
          <Radio.Group
            options={options}
            onChange={onChangeQType}
            value={tableParams.qType}
            optionType="button"
            className="question_buttons"
            style={{ marginLeft: "30px", }}
          />
          <Button key="view" style={{ float: "right" }} className="question_buttons">
            <Link to={`/chat?id=${chatId}`} style={{ textDecoration: "none" }}>
              Return to Chat
            </Link>
          </Button>
          <Table
            style={{ marginTop: '10px' }}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            scroll={{ x: '1000px' }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
      <Modal
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
          >
            Edit
          </div>
        }
        open={editModalOpen}
        onOk={handleEditModalOk}
        onCancel={handleEditModalCancel}
        width={1000}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <Input
          prefix={
            <>
              <span style={{ color: "grey" }}>Question&nbsp;-&nbsp;</span>
            </>
          }
          name="question"
          placeholder="Question"
          value={selectedQA.question}
          onChange={(e) => onChangeQuestion(e)}
          style={{ marginTop: "15px" }}
        />
        <div style={{ display: tableParams.qType == 0 ? "inline" : "none" }}>
          Answer:
          <Radio.Group
            name="radioOption"
            value={
              typeof selectedQA.answer == "number"
                ? selectedQA.answer
                : selectedQA.option_a == selectedQA.answer
                ? 1
                : selectedQA.option_b == selectedQA.answer
                ? 2
                : selectedQA.option_c == selectedQA.answer
                ? 3
                : selectedQA.option_d == selectedQA.answer
                ? 4
                : 0
            }
            onChange={onChangeRadioAnswer}
            style={{ margin: "20px" }}
          >
            <Radio value={1}>A</Radio>
            <Radio value={2}>B</Radio>
            <Radio value={3}>C</Radio>
            <Radio value={4}>D</Radio>
          </Radio.Group>
          <Input
            prefix={
              <>
                <span style={{ color: "grey" }}>A&nbsp;-&nbsp;</span>
              </>
            }
            name="option_a"
            placeholder="Option A"
            value={selectedQA.option_a}
            onChange={(e) => onChangeOptionA(e)}
            style={{ marginTop: "15px" }}
          />
          <Input
            prefix={
              <>
                <span style={{ color: "grey" }}>B&nbsp;-&nbsp;</span>
              </>
            }
            name="option_b"
            placeholder="Option B"
            value={selectedQA.option_b}
            onChange={(e) => onChangeOptionB(e)}
            style={{ marginTop: "15px" }}
          />
          <Input
            prefix={
              <>
                <span style={{ color: "grey" }}>C&nbsp;-&nbsp;</span>
              </>
            }
            name="option_c"
            placeholder="Option C"
            value={selectedQA.option_c}
            onChange={(e) => onChangeOptionC(e)}
            style={{ marginTop: "15px" }}
          />
          <Input
            prefix={
              <>
                <span style={{ color: "grey" }}>D&nbsp;-&nbsp;</span>
              </>
            }
            name="option_d"
            placeholder="Option D"
            value={selectedQA.option_d}
            onChange={(e) => onChangeOptionD(e)}
            style={{ marginTop: "15px" }}
          />
        </div>
        <TextArea
          rows={4}
          placeholder="Answer"
          style={{
            marginTop: "15px",
            display: tableParams.qType == 0 ? "none" : "inline",
          }}
          value={selectedQA.answer}
          onChange={(e) => onChangeTxtareaAnswer(e)}
        />
      </Modal>
      <div
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(255, 255, 255, 0.6)",
          zIndex: 999,
          top: 0,
          left: 0,
          display: `${isLoading ? "flex" : "none"}`,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin tip={loadingTxt}>
          <div style={{ width: "300px", height: "300px" }}></div>
        </Spin>
        <div className="menubar">
              <Drawer title="AI ChatBot" rootClassName="menu_drawer" width={'240px'} placement="right" onClose={onCloseDrawer} open={drawerVisible}>
                <Menu
                  onClick={onMenuClick}
                  style={{
                    width:'100%',
                  }}
                  defaultSelectedKeys={['1']}
                  defaultOpenKeys={['sub1']}
                  mode="inline"
                  items={menuitems}
                />
              </Drawer>
          </div>
      </div>
    </>
  );
};
export default ManageQA;
