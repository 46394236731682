import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import {
  DeleteOutlined,
  CommentOutlined,
  PlusCircleOutlined,
  UploadOutlined,
  MenuOutlined,
  PlusOutlined,
  UserOutlined,
  HomeOutlined,
  ProfileOutlined,
  LogoutOutlined
} from "@ant-design/icons";
import {
  Card,
  Row,
  Col,
  Button,
  Drawer,
  Form,
  Input,
  Upload,
  message,
  Popconfirm,
  Spin,
  Menu
} from "antd";

import { Link } from 'react-router-dom';

import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import BotService from "../services/bot.service";
import AuthService from "../services/auth.service";

const { Meta } = Card;

export default class BoardAdmin extends Component {
  constructor(props) {
    super(props);

    this.onNewChatClick = this.onNewChatClick.bind(this);
    this.onCloseNewCBDrawer = this.onCloseNewCBDrawer.bind(this);
    this.onFinishCreateCBForm = this.onFinishCreateCBForm.bind(this);
    this.onClickChatWithBot = this.onClickChatWithBot.bind(this);
    this.onDelBotConfirm = this.onDelBotConfirm.bind(this);
    this.onCloseDrawer = this.onCloseDrawer.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.logOut = this.logOut.bind(this);

    this.state = {
      bots: [],
      redirect: null,
      openNewChatDrawer: false,
      isLoading: false,
      loadingTxt: "Loading...",
      drawerVisible: false
    };
  }

  onClickChatWithBot(bot) {
    this.setState({ redirect: "/chat?id=" + bot.id });
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) this.setState({ redirect: "/home" });

    this.setState({ isLoading: true });
    BotService.getBots()
      .then((res) => {
        if (res.status == 200) {
          this.setState({
            bots: res.data.data.bots,
          });
        } else if (res.status == 404) {
          message.info("There is no bots.");
        } else {
          message.error(
            "Sorry, but there is a little problem. Try again after a while."
          );
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        message.error(
          `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
            JSON.parse(localStorage.getItem("admin")).email
          }.`
        );
      });
  }

  onCloseDrawer() {
    this.setState({ drawerVisible: false });
  }

  onMenuClick() {
    this.setState({ drawerVisible: false });
  }

  onDelBotConfirm(bot_id, bot_name, namespace) {
    this.setState({ isLoading: true });
    BotService.delBotById(bot_id, namespace)
      .then((res) => {
        if (res.status == 200) {
          BotService.getBots()
            .then((res1) => {
              if (res1.status == 200) {
                this.setState({
                  bots: res1.data.data.bots,
                });
                message.success(
                  `The bot ${bot_name} has been deleted successfully.`
                );
              } else if (res1.status == 404) {
                this.setState({ bots: [] });
                message.success(
                  `The bot ${bot_name} has been deleted successfully.`
                );
                message.info("There is no bots.");
              } else {
                message.error(
                  "Sorry, but there is a little problem. Try again after a while."
                );
              }
            })
            .catch((err) => {
              message.error(
                `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
                  JSON.parse(localStorage.getItem("admin")).email
                }.`
              );
            });
          this.setState({ isLoading: false });
        } else if (res.status == 400) {
          message.error("Wrong Request!");
        } else if (res.status == 501) {
          message.error(
            "Sorry, but the connection with Pinecone is failed. Try again after a while."
          );
        } else if (res.status == 503) {
          message.error(
            "Sorry, but deleting documents is failed. Try again after a while."
          );
        } else {
          message.error(
            "Sorry, but there is a little problem. Try again after a while."
          );
        }
      })
      .catch((err) => {
        message.error(
          `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
            JSON.parse(localStorage.getItem("admin")).email
          }.`
        );
      });
  }

  onNewChatClick(e) {
    this.setState({ openNewChatDrawer: true });
  }

  onCloseNewCBDrawer() {
    this.setState({ openNewChatDrawer: false });
  }

  onFinishCreateCBForm(data) {
    const allowedImgTypelist = [
      {
        type: "image/png",
        extension: ".png",
      },
    ];

    if (
      BotService.isAllowedFile(allowedImgTypelist, data.uploadChatImg.fileList)
    ) {
      this.setState({ isLoading: true });
      BotService.create(data)
        .then((res) => {
          if (res.status == 200) {
            let tempBots = this.state.bots;
            tempBots.push(res.data.data);
            this.setState({ bots: tempBots });
            this.setState({
              openNewChatDrawer: false,
            });
            message.success(
              `The bot ${res.data.data.bot_name} has been created successfully.`
            );
          } else if (res.status == 400) {
            message.error("Wrong Request!");
          } else {
            message.error(
              "Sorry, but there is a little problem. Try again after a while."
            );
          }
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          message.error(
            `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
              JSON.parse(localStorage.getItem("admin")).email
            }.`
          );
        });
    }
  }

  logOut() {
    AuthService.logout();
    window.location.reload();
  }

  render() {

    const items = [
      { item: 'key1', label: <Link to="/home">Home</Link>, icon: <HomeOutlined /> },
      { item: 'key2', label: <Link to="/admin">Admin</Link>, icon: <UserOutlined /> },
      { item: 'key3', label: <Link to="/profile">Profile Setting</Link>, icon: <ProfileOutlined /> },
      { item: 'key4', label: <a onClick={this.logOut}>LogOut</a>, icon: <LogoutOutlined /> }
    ]

    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <Row>
          <Col xs={24} sm={20} className="admin_breadcrumb">
            <header style={{ padding: '20px 25px', textAlign: 'center', backgroundColor: '#5eb2f1', marginBlock: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div className="drawer_button" style={{ color: 'white', fontWeight: 'bold', fontSize: '24px', lineHeight: '0', cursor: 'pointer' }} onClick={() => { this.setState({ drawerVisible: true })}}>
                <MenuOutlined />
              </div>
              <h3 style={{ marginBlock: '0', color: 'white' }}>New Chat</h3>
              <div className="drawer_button" style={{ color: 'white', fontWeight: 'bold', fontSize: '24px', lineHeight: '0', cursor: 'pointer' }} onClick={this.onNewChatClick} >
                <PlusOutlined />
              </div>
            </header>
          </Col>
        </Row>
        <Row>
          <Col span={20} style={{ margin: 'auto' }}>
            <Swiper
              navigation={true}
              modules={[Navigation]}
              className="cardSwiperContainer"
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20
                },
                // when window width is >= 320px
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30
                },
                // when window width is >= 480px
                1280: {
                  slidesPerView: 4,
                  spaceBetween: 30
                },
                1440: {
                  slidesPerView: 5,
                  spaceBetween: 30
                }
              }}
            >
              {this.state.bots.map((bot) => (
                <SwiperSlide key={bot.id}>
                  <Card
                    className="card_item"
                    style={{
                      width: '100%',
                      padding: '20px 20px 0'
                    }}
                    cover={
                      <img
                        alt="img"
                        src={process.env.REACT_APP_BASE_API_URL + bot.img_url}
                        style={{ height: 'auto', maxWidth: 200, width: '100%', margin: 'auto' }}
                      />
                    }
                    actions={[
                      <Popconfirm
                        title="Delete"
                        description="Do you sure that delete this chatbot?"
                        onConfirm={(e) => {
                          e.preventDefault();
                          this.onDelBotConfirm(
                            bot.id,
                            bot.bot_name,
                            bot.namespace
                          );
                        }}
                      >
                        <DeleteOutlined
                          key="edit"
                          style={{ color: "red" }}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </Popconfirm>,
                      <CommentOutlined
                        key="chat"
                        style={{ color: "green" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.onClickChatWithBot(bot);
                        }}
                      />,
                    ]}
                  >
                    <Meta
                      title={<span title={bot.bot_name}>{bot.bot_name}</span>}
                      description={<span title={bot.description}>{bot.description}</span>}
                      style={{ maxHeight: "100" }}
                    />
                  </Card>
                </SwiperSlide>
              ))}
              <SwiperSlide style={{ height: "unset" }} className="new_chatbot_button">
                <Card
                    className="card_item"
                  style={{
                    width: '100%',
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={this.onNewChatClick}
                >
                  <PlusCircleOutlined style={{ fontSize: "30px" }} />{" "}
                  <span>New Chatbot</span>
                </Card>
              </SwiperSlide>
            </Swiper>
          </Col>
        </Row>
        <Drawer
          title="Create a new chatbot"
          width={720}
          onClose={this.onCloseNewCBDrawer}
          open={this.state.openNewChatDrawer}
          bodyStyle={{
            paddingBottom: 80,
          }}
        >
          <Form
            layout="vertical"
            onFinish={this.onFinishCreateCBForm}
            encType="multipart/form-data"
          >
            <Row>
              <Col span={16}>
                <Form.Item
                  name="bot_name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter chat name",
                    },
                    {
                      min: 3,
                      message: "The name must be 3 characters at least",
                    },
                  ]}
                >
                  <Input placeholder="Please enter chat name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "please enter description for chatbot",
                    },
                    {
                      min: 20,
                      message: "The description must be 20 characters at least",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={4}
                    placeholder="please enter description for chatbot"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="uploadChatImg"
                  label="Upload Chat Image"
                  rules={[
                    {
                      required: true,
                      message: "please upload chat image",
                    },
                  ]}
                >
                  <Upload
                    maxCount={1}
                    beforeUpload={() => {
                      return false;
                    }}
                    accept=".png"
                    showUploadList={false}
                  >
                    <Button icon={<UploadOutlined />}>Upload Chat Image</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item shouldUpdate style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    style={{ marginRight: "15px", width: "90px" }}
                    onClick={this.onCloseNewCBDrawer}
                    danger
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "90px" }}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            zIndex: 999,
            top: 0,
            left: 0,
            display: `${this.state.isLoading ? "flex" : "none"}`,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin tip={this.state.loadingTxt}>
            <div style={{ width: "300px", height: "300px" }}></div>
          </Spin>
          <div className="menubar">
            <Drawer title="AI ChatBot" rootClassName="menu_drawer" width={'240px'} placement="right" onClose={this.onCloseDrawer} open={this.state.drawerVisible}>
              <Menu
                onClick={this.onMenuClick}
                style={{
                  width:'100%',
                }}
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                mode="inline"
                items={items}
              />
            </Drawer>
          </div>
        </div>
      </>
    );
  }
}
