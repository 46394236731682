import axios from "axios";
import authHeader from "./auth-header";
import { message } from "antd";

const API_URL = `${process.env.REACT_APP_BASE_API_URL}/api/bot`;

class BotService {
  getBots() {
    return axios
      .get(`${API_URL}/getAllBots`, { headers: authHeader() })
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err;
      });
  }

  getActiveBots() {
    return axios
      .get(`${API_URL}/getActiveBots`, { headers: authHeader() })
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err;
      });
  }

  create(data) {
    let bot_name = data.bot_name;
    let description = data.description;
    let uploadChatImg = data.uploadChatImg.file;

    let formData = new FormData();
    formData.append("bot_name", bot_name);
    formData.append("description", description);
    formData.append("file", uploadChatImg);

    return axios
      .post(`${API_URL}/create`, formData, { headers: authHeader() })
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  getAllTrainData(bot_id) {
    return axios
      .post(
        `${API_URL}/traindata/getAll`,
        { bot_id },
        { headers: authHeader() }
      )
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  uploadTrainData(data) {
    let file = data.file;
    let namespace = data.namespace;
    let bot_id = data.bot_id;

    let formData = new FormData();
    formData.append("namespace", namespace);
    formData.append("bot_id", bot_id);
    formData.append("file", file);

    return axios
      .post(`${API_URL}/traindata/upload`, formData, {
        headers: authHeader(),
      })
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  getReply(namespace, message) {
    return axios
      .post(
        `${API_URL}/getReply`,
        { message, namespace },
        {
          headers: authHeader(),
        }
      )
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  delBotById(bot_id, namespace) {
    return axios
      .post(
        `${API_URL}/delById`,
        { bot_id, namespace },
        { headers: authHeader() }
      )
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  changeBotName(new_name, bot_id) {
    return axios
      .post(
        `${API_URL}/changeName`,
        { bot_id, new_name },
        { headers: authHeader() }
      )
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  changeBotDescription(new_description, bot_id) {
    return axios
      .post(
        `${API_URL}/changeDescription`,
        { bot_id, new_description },
        { headers: authHeader() }
      )
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  changeBotOpenResponse(new_OpenResponse, bot_id) {
    return axios
      .post(
        `${API_URL}/changeOpenResponse`,
        { bot_id, new_OpenResponse },
        { headers: authHeader() }
      )
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  changeBotImg(new_Img, namespace) {
    let formData = new FormData();
    formData.append("namespace", namespace);
    formData.append("file", new_Img);
    return axios
      .post(`${API_URL}/changeImg`, formData, {
        headers: authHeader(),
      })
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  getTrainDataById(trainDataId) {
    return axios
      .post(
        `${API_URL}/traindata/getById`,
        { trainDataId },
        {
          headers: authHeader(),
        }
      )
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  delTrainDataById(
    delTrainDataId,
    delTrainDataFilename,
    delTrainDataIsTrained,
    namespace,
    bot_id
  ) {
    return axios
      .post(
        `${API_URL}/traindata/delById`,
        {
          delTrainDataId,
          delTrainDataFilename,
          delTrainDataIsTrained,
          namespace,
          bot_id,
        },
        { headers: authHeader() }
      )
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  clearTrainDataByBotId(bot_id, namespace) {
    return axios
      .post(
        `${API_URL}/traindata/clearByBotId`,
        { bot_id, namespace },
        { headers: authHeader() }
      )
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  getBotById(bot_id) {
    return axios
      .post(`${API_URL}/getById`, { bot_id }, { headers: authHeader() })
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  reTrainData(id, filename, namespace) {
    return axios
      .post(
        `${API_URL}/traindata/retrain`,
        { id, filename, namespace },
        { headers: authHeader() }
      )
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  getQA(tableParams) {
    return axios
      .post(`${API_URL}/qa/get`, { tableParams }, { headers: authHeader() })
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  delQAbyId(id, qType) {
    return axios
      .post(`${API_URL}/qa/delById`, { id, qType }, { headers: authHeader() })
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  resetQA(trainDataId, qType) {
    return axios
      .post(
        `${API_URL}/qa/reset`,
        { trainDataId, qType },
        { headers: authHeader() }
      )
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  addQA(trainDataId, qType) {
    return axios
      .post(
        `${API_URL}/qa/add`,
        { trainDataId, qType },
        { headers: authHeader() }
      )
      .then(
        (res) => {
          return res;
        },
        (err) => {
          // console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  getQAById(id, qType) {
    return axios
      .post(`${API_URL}/qa/getById`, { id, qType }, { headers: authHeader() })
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  editQA(data) {
    return axios
      .post(`${API_URL}/qa/updateById`, data, { headers: authHeader() })
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  isAllowedFile(allowedTypes, files) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileType = file.type;
      const fileExtension = "." + file.name.split(".").pop();

      let validFlag = false;
      for (let j = 0; j < allowedTypes.length; j++) {
        let allowedType = allowedTypes[j];
        if (
          fileType === allowedType.type ||
          fileExtension === allowedType.extension
        ) {
          validFlag = true;
          break;
        }
      }

      if (!validFlag) {
        message.error("The type of " + file.name + " is invalid");
        return false;
      }
    }
    return true;
  }

  parseURLParams(url) {
    var queryStart = url.indexOf("?") + 1,
      queryEnd = url.indexOf("#") + 1 || url.length + 1,
      query = url.slice(queryStart, queryEnd - 1),
      pairs = query.replace(/\+/g, " ").split("&"),
      parms = {},
      i,
      n,
      v,
      nv;

    if (query === url || query === "") return;

    for (i = 0; i < pairs.length; i++) {
      nv = pairs[i].split("=", 2);
      n = decodeURIComponent(nv[0]);
      v = decodeURIComponent(nv[1]);

      if (!parms.hasOwnProperty(n)) parms[n] = [];
      parms[n].push(nv.length === 2 ? v : null);
    }
    return parms;
  }
}

export default new BotService();
