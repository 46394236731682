import axios from "axios";

const API_URL = `${process.env.REACT_APP_BASE_API_URL}/api/auth/`;

class AuthService {
  login(password) {
    return axios
      .post(API_URL + "signin", {
        password,
      })
      .then((response) => {
        if (response.data.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        }
        return;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(firstname, lastname, email, password) {
    return axios.post(API_URL + "signup", {
      firstname,
      lastname,
      email,
      password,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  getAdminEmail() {
    return axios.get(API_URL + "getAdminEmail").then((response) => {
      if (response.data) return response.data.data.email;
    });
  }

  updateProfile(email, firstname, lastname) {
    return axios
      .post(API_URL + "updateProfile", {
        email,
        firstname,
        lastname,
      })
      .then(
        (res) => {
          let oldProfile = JSON.parse(localStorage.getItem("user"));
          oldProfile = res.data.data;
          localStorage.setItem("user", JSON.stringify(oldProfile));
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }

  updatePassword(oldP, newP) {
    return axios
      .post(API_URL + "updatePassword", {
        oldP,
        newP,
      })
      .then(
        (res) => {
          return res;
        },
        (err) => {
          console.clear();
          return err.response;
        }
      )
      .catch((err) => {
        return err.response;
      });
  }
}

export default new AuthService();
