import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Input,
  Layout,
  Upload,
  Menu,
  Tooltip,
  Spin,
  message,
  Avatar,
  Modal,
  Form,
  Row,
  Col,
  Drawer,
} from "antd";
import {
  UploadOutlined,
  EditOutlined,
  SyncOutlined,
  DownloadOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  MenuOutlined,
  PlusOutlined,
  HomeOutlined,
  UserOutlined,
  ProfileOutlined,
  LogoutOutlined,
  LoginOutlined,
} from "@ant-design/icons";

import BotService from "../services/bot.service";
import AuthService from "../services/auth.service";

const { Content, Sider } = Layout;
const { TextArea } = Input;

function Chat() {
  const [chatId, setChatId] = useState();
  const [messages, setMessages] = useState([]);
  const [sentMsg, setSentMsg] = useState("");
  const [chatBotName, setChatBotName] = useState("");
  const [chatBotId, setChatBotId] = useState("");
  const [chatBotDescription, setChatBotDescription] = useState("");
  const [chatBotOpenResponse, setChatBotOpenResponse] = useState("");
  const [chatBotImgUrl, setChatBotImgUrl] = useState("");
  const [chatBotNamespace, setChatBotNamespace] = useState("");
  const [gettingReply, setGettingReply] = useState(false);
  const [uploadingDoc, setUploadingDoc] = useState(false);
  const [guestMode, setGuestMode] = useState(false);
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [isImgModalOpen, setIsImgModalOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const [confirmNameLoading, setConfirmNameLoading] = useState(false);
  const [newDescription, setNewDescription] = useState("");
  const [confirmDescriptionLoading, setConfirmDescriptionLoading] =
    useState(false);
  const [confirmImgLoading, setConfirmImgLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [openAskViewDelModal, setOpenAskViewDelModal] = useState(false);
  const [selectedTrainData, setSelectedTrainData] = useState("");
  const [loadingViewDelModal, setLoadingViewDelModal] = useState(false);
  const [isOpenResponseModalOpen, setIsOpenResponseModalOpen] = useState(false);
  const [confirmOpenResponseLoading, setConfirmOpenResponseLoading] =
    useState(false);
  const [newOpenResponse, setNewOpenResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTxt, setLoadingTxt] = useState("Loading...");
  const [siderVisible, setSiderVisible] = useState(false);
  const [drawerVisible, setdrawerVisible] = useState(false);
  const [SLogin, setSLogin] = useState(false);

  const [form] = Form.useForm();

  const chatRef = useRef(null);
  const avatarRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);

    setSLogin(localStorage.getItem("user") == null ? true : false);

    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) setGuestMode(true);

    var urlParams = BotService.parseURLParams(window.location.href);
    setChatId(urlParams["id"][0]);
    BotService.getBotById(urlParams["id"][0])
      .then((res) => {
        if (res.status == 200) {
          const chatBot = res.data.data.data[0];
          setChatBotId(chatBot.id);
          setChatBotName(chatBot.bot_name);
          setChatBotImgUrl(
            process.env.REACT_APP_BASE_API_URL + chatBot.img_url
          );
          setChatBotDescription(chatBot.description);
          setChatBotNamespace(chatBot.namespace);
          if (chatBot.open_response != null) {
            setChatBotOpenResponse(chatBot.open_response);
          }
          BotService.getAllTrainData(urlParams["id"][0])
            .then((res) => {
              if (res.status == 200) {
                let data = res.data.data.trainData;
                let tempItems = [];
                for (let i = 0; i < data.length; i++) {
                  let element = {};
                  element.key = data[i].id;
                  element.label = data[i].originalname;
                  element.icon =
                    data[i].is_trained == 0 ? (
                      <CloseOutlined />
                    ) : (
                      <CheckOutlined />
                    );
                  tempItems.push(element);
                }
                setItems(tempItems);
              } else if (res.status == 400) {
                message.error("Wrong Request!");
              } else if (res.status == 404) {
                if (currentUser)
                  message.info(
                    `sorry, but this chatbot can't chat with users because of the lack of training data. Please upload the training data.`
                  );
                else
                  message.info(
                    `sorry, but this chatbot can't chat with users because of the lack of training data. Please contact with ${
                      JSON.parse(localStorage.getItem("admin")).email
                    }.`
                  );
              } else {
                message.error(
                  "Sorry, but there is a little problem. Try again after a while."
                );
              }
            })
            .catch((err) => {
              message.error(
                `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
                  JSON.parse(localStorage.getItem("admin")).email
                }.`
              );
            });
        } else if (res.status == 400) {
          message.error("Wrong Request!");
        } else if (res.status == 404) {
          message.warning("Not Found!");
        } else {
          message.error(
            "Sorry, but there is a little problem. Try again after a while."
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        message.error(
          `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
            JSON.parse(localStorage.getItem("admin")).email
          }.`
        );
      });
  }, []);

  useEffect(() => {
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [messages]);

  const handleSendMessage = () => {
    if (sentMsg.trim()) {
      let data = messages;
      data.push({ text: sentMsg, type: 0 });
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
      setGettingReply(true);
      BotService.getReply(chatBotNamespace, sentMsg)
        .then((res) => {
          if (res.status == 200) {
            res.data.type = 1;
            data.push(res.data);
            setMessages([...data]);
          } else if (res.status == 400) {
            message.error("Wrong Request!");
          } else if (res.status == 501) {
            if (!guestMode)
              message.error(
                "Sorry, but the connection with AI model or Pinecone is failed. Try again after a while."
              );
          } else {
            message.error(
              "Sorry, but there is a little problem. Try again after a while."
            );
          }
          setGettingReply(false);
        })
        .catch((err) => {
          message.error(
            `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
              JSON.parse(localStorage.getItem("admin")).email
            }.`
          );
        });
      setSentMsg("");
    }
  };

  const handleInputChange = (e) => {
    setSentMsg(e.target.value);
  };

  const beforeUpload = (file) => {
    const allowedDocTypelist = [
      {
        type: "application/pdf",
        extension: ".pdf",
      },
      {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        extension: ".docx",
      },
      {
        type: "text/plain",
        extension: ".txt",
      },
    ];
    let filelist = [];
    filelist.push(file);
    if (BotService.isAllowedFile(allowedDocTypelist, filelist)) {
      setUploadingDoc(true);
      setIsLoading(true);
      setLoadingTxt("Uploading data...");
      BotService.uploadTrainData({
        file,
        namespace: chatBotNamespace,
        bot_id: chatBotId,
      })
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            data.icon = <CheckOutlined />;
            addItem(data);
          } else if (res.status == 400) {
            message.error("Wrong Request!");
          } else if (res.status == 501) {
            let data = res.data;
            data.icon = <CloseOutlined />;
            addItem(data);
            message.error("Sorry, but the training has been failed!");
          } else {
            message.error(
              "Sorry, but there is a little problem. Try again after a while."
            );
          }
          setUploadingDoc(false);
          setIsLoading(false);
          setLoadingTxt("Loading...");
        })
        .catch((err) => {
          message.error(
            `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
              JSON.parse(localStorage.getItem("admin")).email
            }.`
          );
        });
    }
  };

  const addItem = (item) => {
    setItems([...items, item]);
  };

  const handleClearChat = () => {
    setMessages([]);
  };

  const handleClearTrainData = () => {
    if (!guestMode) {
      if (items.length) {
        setIsLoading(true);
        setLoadingTxt("Clearing data...");
        BotService.clearTrainDataByBotId(chatBotId, chatBotNamespace)
          .then((res) => {
            if (res.status == 200) {
              message.success(
                "All of the training data have been deleted successfully!"
              );
              setItems([]);
            } else if (res.status == 400) {
              message.error("Wrong Request!");
            } else if (res.status == 501) {
              message.error(
                "Sorry, but the connection with Pinecone is failed. Try again after a while."
              );
            } else {
              message.error(
                "Sorry, but there is a little problem. Try again after a while."
              );
            }
            setIsLoading(false);
            setLoadingTxt("Loading...");
          })
          .catch((err) => {
            message.error(
              `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
                JSON.parse(localStorage.getItem("admin")).email
              }.`
            );
          });
      } else {
        message.info("This Chatbot doesn't have any training data!");
      }
    }
  };

  const handleEditOpenResponse = () => {
    if (!guestMode) {
      setNewOpenResponse(chatBotOpenResponse);
      setIsOpenResponseModalOpen(true);
    }
  };

  const onOpenResponseModalOk = () => {
    if (!newOpenResponse || newOpenResponse.length < 20) {
      message.error("The new Open-Response must be 20 characters at least");
    } else {
      setConfirmOpenResponseLoading(true);
      setIsLoading(true);
      setLoadingTxt("Updating Chatbot Open-Response...");
      BotService.changeBotOpenResponse(newOpenResponse, chatBotId)
        .then((res) => {
          if (res.status == 200) {
            setChatBotOpenResponse(newOpenResponse);
            setIsOpenResponseModalOpen(false);
          } else if (res.status == 400) {
            message.error("Wrong Request!");
          } else {
            message.error(
              "Sorry, but there is a little problem. Try again after a while."
            );
          }
          setConfirmOpenResponseLoading(false);
          setIsLoading(false);
          setLoadingTxt("Loading...");
        })
        .catch((err) => {
          message.error(
            `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
              JSON.parse(localStorage.getItem("admin")).email
            }.`
          );
        });
    }
  };

  const onOpenResponseModalCancel = () => {
    setIsOpenResponseModalOpen(false);
  };

  const onChangeOpenResponseTxtarea = (e) => {
    setNewOpenResponse(e.target.value);
  };

  const handleExportChat = () => {
    if (messages.length) {
      let exportData = "";
      if (chatBotOpenResponse)
        exportData = `${chatBotName}: ${chatBotOpenResponse}\n`;
      messages.map((message) => {
        exportData +=
          (!message.type ? "You" : chatBotName) + ":  " + message.text + "\n";
      });
      const element = document.createElement("a");
      const file = new Blob([exportData], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      let now = new Date();
      element.download =
        "Chat_With_" +
        chatBotName +
        "_" +
        now.getFullYear() +
        "_" +
        (now.getMonth() + 1) +
        "_" +
        now.getDate() +
        "_" +
        now.getHours() +
        "_" +
        now.getMinutes() +
        "_" +
        now.getSeconds() +
        "_" +
        now.getMilliseconds() +
        ".txt";
      document.body.appendChild(element);
      element.click();
    }
  };

  const onClickBotName = () => {
    if (!guestMode) {
      setNewName(chatBotName);
      setIsNameModalOpen(true);
    }
  };

  const onChangeNameInput = (e) => {
    setNewName(e.target.value);
  };

  const onNameModalOk = () => {
    if (!newName || newName.length < 3 || newName.length > 20) {
      message.error("The new name must be between 3 and 20 characters");
    } else {
      setConfirmNameLoading(true);
      setIsLoading(true);
      setLoadingTxt("Updating Chatbot Name...");
      BotService.changeBotName(newName, chatBotId)
        .then((res) => {
          if (res.status == 200) {
            setChatBotName(newName);
            setIsNameModalOpen(false);
          } else if (res.status == 400) {
            message.error("Wrong Request!");
          } else {
            message.error(
              "Sorry, but there is a little problem. Try again after a while."
            );
          }
          setConfirmNameLoading(false);
          setIsLoading(false);
          setLoadingTxt("Loading...");
        })
        .catch((err) => {
          message.error(
            `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
              JSON.parse(localStorage.getItem("admin")).email
            }.`
          );
        });
    }
  };

  const onNameModalCancel = () => {
    setIsNameModalOpen(false);
  };

  const onClickBotDescription = () => {
    if (!guestMode) {
      setNewDescription(chatBotDescription);
      setIsDescriptionModalOpen(true);
    }
  };

  const onChangeDescriptionTxtarea = (e) => {
    setNewDescription(e.target.value);
  };

  const onDescriptionModalOk = () => {
    if (!newDescription || newDescription.length < 20) {
      message.error("The new description must be 20 characters at least");
    } else {
      setConfirmDescriptionLoading(true);
      setIsLoading(true);
      setLoadingTxt("Updating Chatbot Description...");
      BotService.changeBotDescription(newDescription, chatBotId)
        .then((res) => {
          if (res.status == 200) {
            setChatBotDescription(newDescription);
            setIsDescriptionModalOpen(false);
          } else if (res.status == 400) {
            message.error("Wrong Request!");
          } else {
            message.error(
              "Sorry, but there is a little problem. Try again after a while."
            );
          }
          setConfirmDescriptionLoading(false);
          setIsLoading(false);
          setLoadingTxt("Loading...");
        })
        .catch((err) => {
          message.error(
            `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
              JSON.parse(localStorage.getItem("admin")).email
            }.`
          );
        });
    }
  };

  const onDescriptionModalCancel = () => {
    setIsDescriptionModalOpen(false);
  };

  const onClickBotImg = () => {
    if (!guestMode) {
      setIsImgModalOpen(true);
    }
  };

  const onImgModalOk = (val) => {
    const allowedImgTypelist = [
      {
        type: "image/png",
        extension: ".png",
      },
    ];

    if (
      BotService.isAllowedFile(allowedImgTypelist, val.uploadChatImg.fileList)
    ) {
      setConfirmImgLoading(true);
      setIsLoading(true);
      setLoadingTxt("Updating Chatbot Image...");
      BotService.changeBotImg(val.uploadChatImg.file, chatBotNamespace)
        .then((res) => {
          if (res.status == 200) {
            getBase64(val.uploadChatImg.file);
            setIsImgModalOpen(false);
          } else if (res.status == 400) {
            message.error("Wrong Request!");
          } else {
            message.error(
              "Sorry, but there is a little problem. Try again after a while."
            );
          }
          setConfirmImgLoading(false);
          setIsLoading(false);
          setLoadingTxt("Loading...");
        })
        .catch((err) => {
          message.error(
            `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
              JSON.parse(localStorage.getItem("admin")).email
            }.`
          );
        });
    }
  };

  const onImgModalCancel = () => {
    setIsImgModalOpen(false);
  };

  const getBase64 = (img) => {
    const reader = new FileReader();
    reader.readAsDataURL(img);
    reader.addEventListener("load", () => setChatBotImgUrl(reader.result));
  };

  const onClickTrainData = (e) => {
    BotService.getTrainDataById(e.key)
      .then((res) => {
        if (res.status == 200) {
          setSelectedTrainData(res.data.data.data[0]);
          setOpenAskViewDelModal(true);
        } else if (res.status == 400) {
          message.error("Wrong Request!");
        } else if (res.status == 404) {
          message.warning("Not Found!");
        } else {
          message.error(
            "Sorry, but there is a little problem. Try again after a while."
          );
        }
      })
      .catch((err) => {
        message.error(
          `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
            JSON.parse(localStorage.getItem("admin")).email
          }.`
        );
      });
  };

  const onClickViewDelModalCancel = () => {
    setOpenAskViewDelModal(false);
  };

  const onClickViewDelModalDel = () => {
    setLoadingViewDelModal(true);
    setIsLoading(true);
    setLoadingTxt("Deleting the selected Doc...");
    BotService.delTrainDataById(
      selectedTrainData.id,
      selectedTrainData.filename,
      selectedTrainData.is_trained,
      chatBotNamespace,
      chatBotId
    )
      .then((res) => {
        if (res.status == 200) {
          BotService.getAllTrainData(chatBotId)
            .then((res1) => {
              if (res1.status == 200) {
                let data = res1.data.data.trainData;
                let tempItems = [];
                for (let i = 0; i < data.length; i++) {
                  let element = {};
                  element.key = data[i].id;
                  element.label = data[i].originalname;
                  element.icon =
                    data[i].is_trained == 0 ? (
                      <CloseOutlined />
                    ) : (
                      <CheckOutlined />
                    );
                  tempItems.push(element);
                }
                setItems(tempItems);
              } else if (res1.status == 400) {
                message.error("Wrong Request!");
              } else if (res1.status == 404) {
                setItems([]);
                message.info(
                  `sorry, but this chatbot can't chat with users because of the lack of training data. Please upload the training data.`
                );
              } else {
                message.error(
                  "Sorry, but there is a little problem. Try again after a while."
                );
              }
              setOpenAskViewDelModal(false);
            })
            .catch((err) => {
              message.error(
                `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
                  JSON.parse(localStorage.getItem("admin")).email
                }.`
              );
            });
        } else if (res.status == 400) {
          message.error("Wrong Request!");
        } else if (res.status == 501) {
          message.error(
            "Sorry, but the connection with Pinecone is failed. Try again after a while."
          );
        } else if (res.status == 404) {
          setItems([]);
          message.info(
            `sorry, but this chatbot can't chat with users because of the lack of training data. Please upload the training data.`
          );
        } else if (res.status == 503) {
          message.error(
            "Sorry, but deleting documents is failed. Try again after a while."
          );
        } else {
          message.error(
            "Sorry, but there is a little problem. Try again after a while."
          );
        }
        setLoadingViewDelModal(false);
        setIsLoading(false);
        setLoadingTxt("Loading...");
      })
      .catch((err) => {
        message.error(
          `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
            JSON.parse(localStorage.getItem("admin")).email
          }.`
        );
      });
  };

  const onClickViewDelModalRetrain = () => {
    setLoadingViewDelModal(true);
    setIsLoading(true);
    setLoadingTxt("Retraining the selected Doc...");
    BotService.reTrainData(
      selectedTrainData.id,
      selectedTrainData.filename,
      chatBotNamespace
    )
      .then((res) => {
        if (res.status == 200) {
          BotService.getAllTrainData(chatBotId)
            .then((res1) => {
              if (res1.status == 200) {
                let data = res1.data.data.trainData;
                let tempItems = [];
                for (let i = 0; i < data.length; i++) {
                  let element = {};
                  element.key = data[i].id;
                  element.label = data[i].originalname;
                  element.icon =
                    data[i].is_trained == 0 ? (
                      <CloseOutlined />
                    ) : (
                      <CheckOutlined />
                    );
                  tempItems.push(element);
                }
                setItems(tempItems);
              } else if (res1.status == 400) {
                message.error("Wrong Request!");
              } else if (res1.status == 404) {
                setItems([]);
                message.info(
                  `sorry, but this chatbot can't chat with users because of the lack of training data. Please upload the training data.`
                );
              } else {
                message.error(
                  "Sorry, but there is a little problem. Try again after a while."
                );
              }
              setOpenAskViewDelModal(false);
            })
            .catch((err) => {
              message.error(
                `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
                  JSON.parse(localStorage.getItem("admin")).email
                }.`
              );
            });
        } else if (res.status == 400) {
          message.error("Wrong Request!");
        } else if (res.status == 501) {
          message.error(
            "Sorry, but the retraining has been failed. Try again after a while."
          );
        } else {
          message.error(
            "Sorry, but there is a little problem. Try again after a while."
          );
        }
        setLoadingViewDelModal(false);
        setIsLoading(false);
        setLoadingTxt("Loading...");
      })
      .catch((err) => {
        message.error(
          `Sorry, but there seem to be a little problem on server side. Please refresh page and contact with ${
            JSON.parse(localStorage.getItem("admin")).email
          }.`
        );
      });
  };

  const onMenuClick = () => {
    setdrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setdrawerVisible(false);
  };

  const logOut = () => {
    AuthService.logout();
    window.location.href = "/home";
  };

  const menuitems1 = [
    {
      item: "key1",
      label: <Link to="/login">LogIn</Link>,
      icon: <LoginOutlined />,
    },
    {
      item: "key2",
      label: <Link to="/home">Home</Link>,
      icon: <HomeOutlined />,
    },
  ];

  const menuitems2 = [
    {
      item: "key1",
      label: <Link to="/home">Home</Link>,
      icon: <HomeOutlined />,
    },
    {
      item: "key2",
      label: <Link to="/admin">Admin</Link>,
      icon: <UserOutlined />,
    },
    {
      item: "key3",
      label: <Link to="/profile">Profile Setting</Link>,
      icon: <ProfileOutlined />,
    },
    {
      item: "key4",
      label: <a onClick={logOut}>LogOut</a>,
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <>
      <Row>
        <Col xs={24} sm={22} className="admin_breadcrumb">
          <header
            style={{
              padding: "20px 25px",
              textAlign: "center",
              backgroundColor: "#5eb2f1",
              marginBlock: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              className="drawer_button"
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "24px",
                lineHeight: "0",
                cursor: "pointer",
              }}
              onClick={() => {
                setdrawerVisible(true);
              }}
            >
              <MenuOutlined />
            </div>
            <h3 style={{ marginBlock: "0", color: "white" }}>Chat</h3>
            <div
              className="drawer_button"
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "24px",
                lineHeight: "0",
                cursor: "pointer",
              }}
              onClick={() => {
                handleClearChat();
              }}
            >
              <PlusOutlined />
            </div>
          </header>
        </Col>
      </Row>
      <Layout style={{ height: "calc(100vh - 56px)" }}>
        {!guestMode && (
          <Sider
            theme="light"
            width={250}
            style={{
              backgroundColor: "rgb(0, 21, 41)",
              position: "relative",
              zIndex: "1",
              padding: 8,
              marginLeft: `${siderVisible ? "5px" : "-255px"}`,
              wordWrap: "break-word",
            }}
          >
            <div
              className="controlSiderButton"
              onClick={() => setSiderVisible(!siderVisible)}
              style={{ marginRight: `${siderVisible ? "11px" : "0px"}` }}
            >
              {siderVisible ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
            </div>
            <h1
              style={{
                color: "white",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={onClickBotName}
            >
              {chatBotName}
            </h1>
            <p
              style={{ color: "white", cursor: "pointer" }}
              onClick={onClickBotDescription}
            >
              {chatBotDescription}
            </p>

            <Spin spinning={uploadingDoc}>
              <Upload.Dragger
                className="custom-uploader"
                beforeUpload={beforeUpload}
                accept={[".pdf", ".docx", ".txt"]}
              >
                <p className="ant-upload-drag-icon">
                  <span>+ Add Document</span>
                </p>
                <p className="ant-upload-text">
                  <span>*.pdf, *.docx, *.txt</span>
                </p>
                <p className="ant-upload-hint">
                  {/* add your custom hint text */}
                </p>
              </Upload.Dragger>
            </Spin>
            <Menu
              defaultSelectedKeys={[]}
              mode="inline"
              theme="dark"
              items={items}
              onClick={onClickTrainData}
            />
          </Sider>
        )}
        <Layout style={{ position: "relative" }}>
          <h4 style={{ paddingLeft: "43px", paddingTop: "20px" }}>
            <Avatar
              ref={avatarRef}
              src={chatBotImgUrl}
              style={{ cursor: "pointer" }}
              onClick={onClickBotImg}
            />
            &nbsp; {chatBotName}
          </h4>
          <Content
            style={{
              overflow: "auto",
              flex: 1,
              margin: "auto",
              width: "100%",
              padding: "40px 10% 20px 10%",
            }}
            ref={chatRef}
          >
            <div style={{ position: "absolute", right: "20px", top: "20px" }}>
              <Tooltip title="Clear Chat">
                <Button type="text" size={"small"} onClick={handleClearChat}>
                  <SyncOutlined />
                </Button>
              </Tooltip>
              {!guestMode && (
                <>
                  <Tooltip title="Clear TrainDatas">
                    <Button
                      type="text"
                      size={"small"}
                      onClick={handleClearTrainData}
                    >
                      <DeleteOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Edit Open-Response">
                    <Button
                      type="text"
                      size={"small"}
                      onClick={handleEditOpenResponse}
                    >
                      <EditOutlined />
                    </Button>
                  </Tooltip>
                </>
              )}
            </div>
            {chatBotOpenResponse && (
              <div
                key={-1}
                style={{
                  marginBottom: 8,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  className="messageBox"
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                    color: "#000",
                    padding: "8px",
                    maxWidth: "calc(100% - 400px)",
                  }}
                >
                  {chatBotOpenResponse}
                </div>
              </div>
            )}
            {messages.map((message, index) => (
              <div
                key={index}
                style={{
                  marginBottom: 8,
                  display: "flex",
                  justifyContent: `${
                    message.type == 1 ? "flex-start" : "flex-end"
                  }`,
                }}
              >
                <div
                  className="messageBox"
                  style={{
                    backgroundColor: `${
                      message.type == 1 ? "#ffffff" : "#1777ff"
                    }`,
                    borderRadius: "8px",
                    color: `${message.type == 1 ? "#000" : "white"}`,
                    padding: "8px",
                    maxWidth: "calc(100% - 400px)",
                  }}
                >
                  {message.text}
                </div>
              </div>
            ))}
            {gettingReply && (
              <div
                style={{
                  marginBottom: 8,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#f9f9fe",
                    borderRadius: "8px",
                    color: "#222",
                    padding: "8px",
                  }}
                >
                  <Spin spinning={gettingReply}></Spin>
                </div>
              </div>
            )}
          </Content>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "auto",
              marginBottom: "16px",
              maxWidth: "768px",
              width: "80%",
            }}
          >
            <Tooltip title="Export Chat">
              <Button type="text" size={"small"} onClick={handleExportChat}>
                <DownloadOutlined />
              </Button>
            </Tooltip>
            <Input
              value={sentMsg}
              onChange={handleInputChange}
              onPressEnter={handleSendMessage}
              disabled={!items.length || gettingReply}
              placeholder={`${
                items.length > 0
                  ? "Ask any question about all documents you uploaded..."
                  : "Upload the documents to train..."
              }`}
            />
            <Button
              type="primary"
              onClick={handleSendMessage}
              loading={gettingReply}
              disabled={!items.length}
              style={{
                marginLeft: 8,
                backgroundColor: "#4E6EF5",
                borderColor: "#4E6EF5",
              }}
            >
              Send
            </Button>
          </div>
        </Layout>
        <Modal
          title="Change name"
          open={isNameModalOpen}
          onOk={onNameModalOk}
          onCancel={onNameModalCancel}
          confirmLoading={confirmNameLoading}
        >
          <Input
            type="text"
            className="form-control"
            name="changeName"
            value={newName}
            onChange={(e) => onChangeNameInput(e)}
          />
        </Modal>
        <Modal
          title="Change Description"
          open={isDescriptionModalOpen}
          onOk={onDescriptionModalOk}
          onCancel={onDescriptionModalCancel}
          confirmLoading={confirmDescriptionLoading}
        >
          <TextArea
            rows={4}
            value={newDescription}
            onChange={(e) => onChangeDescriptionTxtarea(e)}
          />
        </Modal>
        <Modal
          title="Change Open-Response"
          open={isOpenResponseModalOpen}
          onOk={onOpenResponseModalOk}
          onCancel={onOpenResponseModalCancel}
          confirmLoading={confirmOpenResponseLoading}
        >
          <TextArea
            rows={4}
            value={newOpenResponse}
            onChange={(e) => onChangeOpenResponseTxtarea(e)}
          />
        </Modal>
        <Modal
          open={isImgModalOpen}
          title="Change Image"
          confirmLoading={confirmImgLoading}
          onCancel={onImgModalCancel}
          onOk={() => {
            form.validateFields().then((values) => {
              form.resetFields();
              onImgModalOk(values);
            });
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="chngImgForm"
            style={{ marginTop: "30px", marginLeft: "10px" }}
          >
            <Form.Item
              name="uploadChatImg"
              rules={[
                {
                  required: true,
                  message: "please upload chat image",
                },
              ]}
            >
              <Upload
                maxCount={1}
                beforeUpload={() => {
                  return false;
                }}
                accept=".png"
              >
                <Button icon={<UploadOutlined />}>Upload Chat Image</Button>
              </Upload>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          open={openAskViewDelModal}
          title={selectedTrainData.originalname}
          footer={[
            <Button
              key="back"
              onClick={onClickViewDelModalCancel}
              className="question_buttons"
            >
              Return
            </Button>,
            <Button key="view" type="primary" className="question_buttons">
              <a
                href={
                  process.env.REACT_APP_BASE_API_URL + selectedTrainData.doc_url
                }
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                View
              </a>
            </Button>,
            <Button
              key="qa"
              type="primary"
              style={{ backgroundColor: "steelblue" }}
              className="question_buttons"
            >
              <Link
                to={`/manageQA?id=${selectedTrainData.id}&chatid=${chatId}`}
                style={{ textDecoration: "none" }}
              >
                Q&A
              </Link>
            </Button>,
            <Button
              key="retrain"
              type="primary"
              loading={loadingViewDelModal}
              onClick={onClickViewDelModalRetrain}
              style={{
                display: selectedTrainData.is_trained == 0 ? "inline" : "none",
                backgroundColor: "orange",
              }}
              className="question_buttons"
            >
              Retrain
            </Button>,
            <Button
              className="question_buttons"
              key="delete"
              type="primary"
              loading={loadingViewDelModal}
              onClick={onClickViewDelModalDel}
              danger
            >
              Delete
            </Button>,
          ]}
          onCancel={onClickViewDelModalCancel}
        >
          <p>
            Click View to see this document, and{" "}
            {selectedTrainData.is_trained == 0
              ? "Retrain for retraining"
              : "Delete to remove"}
            . Otherwise click Return.
          </p>
        </Modal>
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            zIndex: 999,
            top: 0,
            left: 0,
            display: `${isLoading ? "flex" : "none"}`,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin tip={loadingTxt}>
            <div style={{ width: "300px", height: "300px" }}></div>
          </Spin>
          <div className="menubar">
            <Drawer
              title="AI ChatBot"
              rootClassName="menu_drawer"
              width={"240px"}
              placement="right"
              onClose={onCloseDrawer}
              open={drawerVisible}
            >
              <Menu
                onClick={onMenuClick}
                style={{
                  width: "100%",
                }}
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={["sub1"]}
                mode="inline"
                items={SLogin ? menuitems1 : menuitems2}
              />
            </Drawer>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Chat;
